export const projectsData = [
  {
    id: 1,
    title: "ShareMe",
    desc: "I created a ShareMe application. You can upload picture, write a comment and sign in to your Google account. This social media's frontend is in React and backend is in Sanity.",
    tools: ["React", "Css", "JavaScript", "Tailwind", "Sanity"],
    url: "https://www.shareme.uz/",
    github: "https://github.com/Gayratjon2003/shareme_frontend",
    img: "project5.png",
  },
  {
    id: 2,
    title: "Cryptoverse",
    desc: "I created React website. It's Cryptoverse. I used a lot of technologies such as Chartjs, RTK Query and more.I also used Ant design. Ant design is very good library.You can find a lot of cryptocurrencies, news, exchanges stats and more. Visit to website and enjoy it",
    tools: ["React", "Redux", "Css", "JavaScript", "Chartjs", "Ant Design"],
    url: "https://gayratjon2003.github.io/cryptoverse/",
    github: "https://github.com/Gayratjon2003/cryptoverse",
    img: "project1.png",
  },
  {
    id: 3,
    title: "Gosto",
    desc: "I created E-commercial website. I used Html, Css, JSX, React, Redux Redux-thunk and more",
    tools: ["Html", "Css", "JSX", "React", "Redux", "Redux-thunk"],
    url: "https://gosto.netlify.app/",
    github: "https://github.com/Gayratjon2003/Gosto",
    img: "project2.png",
  },
  {
    id: 4,
    title: "Translator",
    desc: "I created website and it is in React. This website has more than 120 language. You can translate a lot of words and sentences. If you want to auto translate, just click auto translate in the footer. Visit to the website and enjoy it",
    tools: ["Html", "Css", "JavaScript", "React", "RTK Query"],
    url: "https://gayratjon2003.github.io/translator-unlimit/",
    github: "https://github.com/Gayratjon2003/translator-unlimit",
    img: "project3.png",
  },
  {
    id: 5,
    title: "Movie App",
    desc: "I created movie-app website. I used JSX,Sass, React, Redux and API in https://www.omdbapi.com/ . I deployed on github pages.",
    tools: ["Html", "Sass", "Jsx", "React", "Redux", "omdbApi"],
    url: "https://gayratjon2003.github.io/movie-app/",
    github: "https://github.com/Gayratjon2003/movie-app",
    img: "project4.png",
  },
];

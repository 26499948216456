export const experienceData = [
  {
    date: "May, 2023 - Present",
    company: "Itransition Group",
    url: "https://www.itransition.com",
    description: "",
    role: "Software development Internship",
    works: [
      "I did a lot of tasks in this company. I used React, Redux toolkit, Axios, Material UI, Ant Design, Css for frontend.",
      "I use Nodejs, Exressjs for backend and I also used MongoDB for database.",
      "I hosted the frontend on Netlify and Vercel. I hosted backend on the Cyclic and Render.",
      "My final project was https://collections-manager.netlify.app",
    ],
  },
  {
    date: "April, 2023 - Present",
    company: "Koinot Software Interprise",
    url: "https://bazark.vercel.app/en",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I worked e-commerce website named bazark. I created user profile page, payment page, footer page, navbar page and more.",
      "I used material UI, Redux toolkit, axios and more",
    ],
  },
  {
    date: "March, 2023 - April",
    company: "Jobo.uz",
    url: "https://www.jobo.uz",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I created search filters on the jobo.uz site while working for this company. I created a website that takes tests for new employees",
      "I also created this website: https://www.endomarine-kids.uz",
      "You can write your name and phone number",
      "It will be see on the telegram",
    ],
  },
  {
    date: "January, 2023 - April",
    company: "ShareMe",
    url: "https://www.shareme.uz/",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I created a ShareMe application.",
      "You can upload picture, write a comment and sign in to your Google account",
      "This social media's frontend is in React and backend is in Sanity",
      "Visit to the website and enjoy it",
    ],
  },
  {
    date: "December, 2022 - January, 2023",
    company: "Cryptoverse",
    url: "https://gayratjon2003.github.io/cryptoverse/",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I created React website. It's Cryptoverse. I used a lot of technologies such as Chartjs, RTK Query and more",
      "I also used Ant design. Ant design is very good library",
      "You can find a lot of cryptocurrencies, news, exchanges stats and more. Visit to website and enjoy it",
    ],
  },
  {
    date: "November, 2022",
    company: "Gosto",
    url: "https://gosto.netlify.app/",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I created E-commercial website. I used Html, Css, JSX, React, Redux Redux-thunk and more",
    ],
  },
  {
    date: "January, 2022 - November, 2022",
    company: "TenX",
    url: "https://gayratjon2003.github.io/TenX/login",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I created React website. I used Html, Css, Sass, JavaScript, React, Redux and some libraries. I created Backend in Strapi.",
      "This website has a lot of video lessons. You can learn Javascript language",
    ],
  },
];
